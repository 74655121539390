import axios from "axios";
import { isTokenAvailable } from "../lib/helpers/tokenAvailability";
import { USER_TOKEN_KEY } from "../lib/const/localstorage";




const $api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false
})

$api.interceptors.request.use((config: any) => {

    if (isTokenAvailable()) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(USER_TOKEN_KEY)}`
    }
    return config;
})


const clearLocalStorage = () => {
    localStorage.removeItem(USER_TOKEN_KEY)
    window.location.replace('/')
    window.location.reload()

}
const refreshAccessToken = async () => {
    try {
        if (isTokenAvailable()) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/renew_token`, {
                userToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3R1c2VyQG1haWwucnUiLCJleHAiOjE3Mjg3MTM2NDguMTkzOTA2NX0.azkXG_qWRLrNivV7Wwt6pOgrmTDDMCeLfc9sTRJPbN0',
            });
            localStorage.setItem(USER_TOKEN_KEY, response.data.userToken);
            return response.data.userToken;
        }
        return false
    } catch (error) {
        clearLocalStorage()
    }
};
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3R1c2VyQG1haWwucnUiLCJleHAiOjE3Mjg3MTM5NjEuOTQ1MTE4NH0.LfUvbuJPFsr1rFVGZ6oe5gO2O48Txq6UGg5e4n2z_TI

$api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Помечаем, что мы уже пытались повторить запрос
            const newUserToken: boolean | string = await refreshAccessToken(); // Получаем новый access токен
            if (!newUserToken) { // останавливаем если приходит false
                return
            }
            originalRequest.headers['Authorization'] = `Bearer ${newUserToken}`; // Обновляем токен в текущем запросе
            originalRequest.headers['Authorization'] = `Bearer ${newUserToken}`; // Обновляем токен в текущем запросе
            return $api(originalRequest); // Повторяем запрос с новым токеном
        }
        return Promise.reject(error); // Передаем ошибку дальше, если условия не совпадают
    }
);


export default $api